import {
  Card,
  Container,
  MenuItem,
  Popover,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { headerApi } from 'src/utils/headerApi';
import { useLocation } from 'react-router-dom';
import Scrollbar from 'src/components/scrollbar';
import { UserListHead } from 'src/sections/@dashboard/user';
import SkeletonTable from 'src/components/SkeletonTabel';
import LessonTableRow from 'src/sections/@dashboard/lessons/LessonTableRow';
import USERLIST from 'src/_mock/user';
import Iconify from 'src/components/iconify';
import DeleteLesson from 'src/sections/@dashboard/lessons/DeleteLesson';

const TABLE_HEAD = [
  { id: 'hours', label: 'Hours', alignRight: false },
  { id: 'total_price', label: 'Total Price', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'is_free', label: 'Is Free', alignRight: false },
  { id: 'teacher', label: 'Teacher', alignRight: false },
  { id: 'student', label: 'Student', alignRight: false },
  { id: '' },
];

const LessonsDetails = () => {
  const location = useLocation();
  const { pathname, search, hash } = location;

  const [loading, setLoading] = useState(true);

  const { id } = useParams();
  const { token } = useSelector((state) => state.auth);

  const [lessons, setLessons] = useState([]);

  useEffect(() => {
    const type = pathname === '/dashboard/teacher/lessons/1' ? 'Teacher' : 'Student';

    console.log('type', type);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}admin/lessons/get_user_lessons`,
        { id, type },
        {
          headers: headerApi(token),
        }
      )
      .then((res) => {
        console.log(res);
        setLessons(res.data.lessons);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const [selectedId, setSelectedId] = useState('');

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = (event, element, id) => {
    setSelectedId(id);
    setAnchorEl(event.currentTarget);
  };

  // start table

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  //handle open popover
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [openDelete, setOpenDelete] = useState(false);

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  return (
    <>
      <Helmet>
        <title> User | Minimal UI </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mt={5} mb={5}>
          <Typography variant="h4" gutterBottom>
            Lessons
          </Typography>
        </Stack>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {loading ? (
                    <SkeletonTable number={5} />
                  ) : (
                    lessons
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((element, index) => (
                        <LessonTableRow key={index} element={element} handleOpenMenu={handleOpenMenu} />
                      ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => setOpenDelete(true)}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Delete country
        </MenuItem>
      </Popover>

      <DeleteLesson
        open={openDelete}
        handleClose={handleCloseDelete}
        id={selectedId}
        setData={setLessons}
        handleCloseMenu={handleCloseMenu}
      />
    </>
  );
};

export default LessonsDetails;
