import {
    Button,
    Card,
    Container,
    MenuItem,
    Popover,
    Stack,
    Table,
    TableBody,
    TableContainer,
    TablePagination,
    Typography,
  } from '@mui/material';
  import axios from 'axios';
  import React, { useEffect, useState } from 'react';
  import { Helmet } from 'react-helmet-async';
  import { useSelector } from 'react-redux';
  import SkeletonTable from 'src/components/SkeletonTabel';
  import Iconify from 'src/components/iconify';
  import Scrollbar from 'src/components/scrollbar';
  import SubjectTableRow from 'src/sections/@dashboard/subject/SubjectTableRow';
  import { UserListHead } from 'src/sections/@dashboard/user';
  import { headerApi } from 'src/utils/headerApi';
  import USERLIST from 'src/_mock/user';
  import DeleteSubject from 'src/sections/@dashboard/subject/DeleteSubject';
  import AddSubject from 'src/sections/@dashboard/subject/AddSubject';
  import UpdateSubject from 'src/sections/@dashboard/subject/UpdateSubject';
  
  const TABLE_HEAD = [{ id: 'name', label: 'Name', alignRight: false }, { id: '' }];
  
  const Subject = () => {
    const { token } = useSelector((state) => state.auth);
  
    const [subjects, setSubjects] = useState([]);
  
    const [loadingSubjects, setLoadingSubjects] = useState(true);
  
    const [page, setPage] = useState(0);
  
    const [rowsPerPage, setRowsPerPage] = useState(5);
  
    const [anchorEl, setAnchorEl] = useState(null);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setPage(0);
      setRowsPerPage(parseInt(event.target.value, 10));
    };
  
    const handleCloseMenu = () => {
      setAnchorEl(null);
    };
  
    const [selectedElement, setSelectedElement] = useState(null);
  
    const [selectedId, setSelectedId] = useState(null);
  
    const handleOpenMenu = (event, element, id) => {
      setSelectedId(id);
      setSelectedElement(element);
      setAnchorEl(event.currentTarget);
    };
  
    useEffect(() => {
      axios
        .get(`${process.env.REACT_APP_API_URL}admin/subjects`, {
          headers: headerApi(token),
        })
        .then((res) => {
          setSubjects(res.data.subjects);
          setLoadingSubjects(false);
        })
        .catch((error) => {
          console.log(error);
          setLoadingSubjects(false);
        });
    }, []);
  
    //handle delete
    const [openDelete, setOpenDelete] = useState(false);
  
    const handleCloseDelete = () => {
      setOpenDelete(false);
    };
  
    //handle add
  
    const [openAdd, setOpenAdd] = useState(false);
  
    const handleCloseAdd = () => {
      setOpenAdd(false);
    };
  
    // handle update
    const [openUpdate, setOpenUpdate] = useState(false);
  
    const handleCloseUpdate = () => {
      setOpenUpdate(false);
    };
  
    return (
      <>
        <Helmet>
          <title> User | Minimal UI </title>
        </Helmet>
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Subject
            </Typography>
            <Button onClick={() => setOpenAdd(true)} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
              New Subject
            </Button>
          </Stack>
          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead headLabel={TABLE_HEAD} />
                  <TableBody>
                    {loadingSubjects ? (
                      <SkeletonTable number={1} />
                    ) : (
                      subjects
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((element, index) => (
                          <SubjectTableRow key={index} element={element} handleOpenMenu={handleOpenMenu} />
                        ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={USERLIST.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuItem onClick={() => setOpenUpdate(true)}>
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            Update subject
          </MenuItem>
          {/* <MenuItem onClick={() => setOpenDelete(true)}>
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            Delete subject
            </MenuItem> */}
        </Popover>
  
        <AddSubject open={openAdd} setOpen={setOpenAdd} handleClose={handleCloseAdd} setData={setSubjects} />
        {/* <DeleteSubject
          open={openDelete}
          handleClose={handleCloseDelete}
          setData={setSubjects}
          id={selectedId}
          handleCloseMenu={handleCloseMenu}
        /> */}
        <UpdateSubject
          open={openUpdate}
          setOpen={setOpenUpdate}
          setData={setSubjects}
          handleCloseMenu={handleCloseMenu}
          element={selectedElement}
        />
      </>
    );
  };
  
  export default Subject;
  