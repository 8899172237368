import { Avatar, IconButton, Link, Stack, TableCell, TableRow, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Iconify from 'src/components/iconify';

const StudentTableRow = ({ student, handleOpenMenu, mainPage }) => {
  const handleNavigate = (id) => {
    if (mainPage) {
      navigate(`/dashboard/students/lessons/${id}`);
    }
  };

  const navigate = useNavigate();
  return (
    <TableRow
      hover
      tabIndex={-1}
      role="checkbox"
      sx={{ cursor: mainPage ? 'pointer' : '' }}
      onClick={() => handleNavigate(student.id)}
    >
      <TableCell component="th" scope="row" padding="none">
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar alt={student.name} src={`${process.env.REACT_APP_API_URL_IMAGE}${student.image}`} />
          <Typography variant="subtitle2" noWrap>
            {student.name}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell align="left">{student.phone}</TableCell>

      <TableCell align="left">{student.email}</TableCell>
      <TableCell align="left">{student.gender}</TableCell>
      <TableCell align="left">{student.country.name_ar}</TableCell>



      {/* <TableCell align="left">{student.grade.name}</TableCell>
      <TableCell align="left">{student.subject.name}</TableCell>
      <TableCell align="left">{student.country.name}</TableCell> */}

      <TableCell align="right">
        <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, student, student.id)}>
          <Iconify icon={'eva:more-vertical-fill'} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default StudentTableRow;
