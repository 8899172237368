import {
    Button,
    Card,
    Container,
    MenuItem,
    Popover,
    Stack,
    Table,
    TableBody,
    TableContainer,
    TablePagination,
    Typography,
  } from '@mui/material';
  import axios from 'axios';
  import React, { useEffect, useState } from 'react';
  import { Helmet } from 'react-helmet-async';
  import { useSelector } from 'react-redux';
  import SkeletonTable from 'src/components/SkeletonTabel';
  import Iconify from 'src/components/iconify';
  import Scrollbar from 'src/components/scrollbar';
  import CountryTableRow from 'src/sections/@dashboard/country/CountryTableRow';
  import { UserListHead } from 'src/sections/@dashboard/user';
  import { headerApi } from 'src/utils/headerApi';
  import USERLIST from 'src/_mock/user';
  import DeleteCountry from 'src/sections/@dashboard/country/DeleteCountry';
  import AddCountry from 'src/sections/@dashboard/country/AddCountry';
  import UpdateCountry from 'src/sections/@dashboard/country/UpdateCountry';
  
  const TABLE_HEAD = [{ id: 'name', label: 'Name', alignRight: false }, { id: '' }];
  
  const Country = () => {
    const { token } = useSelector((state) => state.auth);
  
    const [countries, setCountries] = useState([]);
  
    const [loadingCountries, setLoadingCountries] = useState(true);
  
    const [page, setPage] = useState(0);
  
    const [rowsPerPage, setRowsPerPage] = useState(5);
  
    const [anchorEl, setAnchorEl] = useState(null);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setPage(0);
      setRowsPerPage(parseInt(event.target.value, 10));
    };
  
    const handleCloseMenu = () => {
      setAnchorEl(null);
    };
  
    const [selectedElement, setSelectedElement] = useState(null);
  
    const [selectedId, setSelectedId] = useState(null);
  
    const handleOpenMenu = (event, element, id) => {
      setSelectedId(id);
      setSelectedElement(element);
      setAnchorEl(event.currentTarget);
    };
  
    useEffect(() => {
      axios
        .get(`${process.env.REACT_APP_API_URL}admin/countries`, {
          headers: headerApi(token),
        })
        .then((res) => {
          setCountries(res.data.countries);
          setLoadingCountries(false);
        })
        .catch((error) => {
          console.log(error);
          setLoadingCountries(false);
        });
    }, []);
  
    //handle delete
    const [openDelete, setOpenDelete] = useState(false);
  
    const handleCloseDelete = () => {
      setOpenDelete(false);
    };
  
    //handle add
  
    const [openAdd, setOpenAdd] = useState(false);
  
    const handleCloseAdd = () => {
      setOpenAdd(false);
    };
  
    // handle update
    const [openUpdate, setOpenUpdate] = useState(false);
  
    const handleCloseUpdate = () => {
      setOpenUpdate(false);
    };
  
    return (
      <>
        <Helmet>
          <title> User | Minimal UI </title>
        </Helmet>
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Country
            </Typography>
            <Button onClick={() => setOpenAdd(true)} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
              New Country
            </Button>
          </Stack>
          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead headLabel={TABLE_HEAD} />
                  <TableBody>
                    {loadingCountries ? (
                      <SkeletonTable number={1} />
                    ) : (
                      countries
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((element, index) => (
                          <CountryTableRow key={index} element={element} handleOpenMenu={handleOpenMenu} />
                        ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={USERLIST.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuItem onClick={() => setOpenUpdate(true)}>
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            Update country
          </MenuItem>
          {/* <MenuItem onClick={() => setOpenDelete(true)}>
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            Delete country
            </MenuItem> */}
        </Popover>
  
        <AddCountry open={openAdd} setOpen={setOpenAdd} handleClose={handleCloseAdd} setData={setCountries} />
        {/* <DeleteCountry
          open={openDelete}
          handleClose={handleCloseDelete}
          setData={setCountries}
          id={selectedId}
          handleCloseMenu={handleCloseMenu}
        /> */}
        <UpdateCountry
          open={openUpdate}
          setOpen={setOpenUpdate}
          setData={setCountries}
          handleCloseMenu={handleCloseMenu}
          element={selectedElement}
        />
      </>
    );
  };
  
  export default Country;
  