import React from 'react';
import Country from 'src/sections/@dashboard/country/Country';
import Grade from 'src/sections/@dashboard/grade/Grades';
import Subject from 'src/sections/@dashboard/subject/Subject';

const Items = () => {
  return (
    <>
      <Country />
      <div style={{ marginTop: '100px' }}>
        <Subject />
      </div>
      <div style={{marginTop: "100px"}}>
        <Grade />
      </div>
    </>
  );
};

export default Items;
