import { IconButton, TableCell, TableRow } from '@mui/material';
import React from 'react';
import Iconify from 'src/components/iconify';

const LessonTableRow = ({element, handleOpenMenu}) => {
  return (
    <TableRow hover tabIndex={-1} role="checkbox">
      <TableCell align="left">{element.hours}</TableCell>
      <TableCell align="left">{element.total_price}</TableCell>
      <TableCell align="left">{element.status}</TableCell>
      <TableCell align="left">{element.is_free}</TableCell>
      <TableCell align="left">{element.teacher_details.name}</TableCell>
      <TableCell align="left">{element.student_details.name}</TableCell>

      <TableCell align="right">
        <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, element, element.id)}>
          <Iconify icon={'eva:more-vertical-fill'} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default LessonTableRow;
